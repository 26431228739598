import loadable from '@loadable/component';
import styled from '@emotion/styled'
import React, { useEffect, useRef, useState, lazy, Suspense } from 'react'
import Switch from 'react-switch'

import Chinese from './chinese.svg'
import English from './english.svg'
import Smile from './smile.svg'
import useOutsideAlerter from './useOutsideAlerter'
import Word from './font'
import { CartContext, useShopStoreIncontext } from '../../context/shopContext'
const splitter = (list) => {
  const segmenter = new Intl.Segmenter();
  return [...segmenter.segment(list)].map(val => val.segment);
}

const Tick = 'https://cdn.july.com/personalisation/personalisation/tick.svg'
const GreenTick = 'https://cdn.july.com/personalisation/personalisation/circle-tick-copy-2.svg'
const Cross = 'https://cdn.july.com/personalisation/personalisation/back-x.svg'

const Location = 'https://cdn.july.com/personalisation/personalisation/location.svg'
const LocationGrey = 'https://cdn.july.com/personalisation/personalisation/location-grey.svg'
const Color = 'https://cdn.july.com/personalisation/personalisation/color.svg'
const ColorGrey = 'https://cdn.july.com/personalisation/personalisation/color-grey.svg'
const Font = 'https://cdn.july.com/personalisation/personalisation/font.png'
const FontGrey = 'https://cdn.july.com/personalisation/personalisation/font-grey.png'

const BlockSvg = 'https://cdn.july.com/personalisation/personalisation/block.svg'
const ScriptSvg = 'https://cdn.july.com/personalisation/personalisation/script.svg'
const CasualSvg = 'https://cdn.july.com/personalisation/personalisation/casual.svg'
const SerifSvg = 'https://cdn.july.com/personalisation/personalisation/serif.svg'
const WideSvg = 'https://cdn.july.com/personalisation/personalisation/wide.svg'

const CollegeSvg = 'https://cdn.july.com/personalisation/personalisation/college.svg'
const PaintedSvg = 'https://cdn.july.com/personalisation/personalisation/painted.svg'
const RetroSvg = 'https://cdn.july.com/personalisation/personalisation/retro.svg'
const SignatureSvg = 'https://cdn.july.com/personalisation/personalisation/signature.svg'

const ModernSerif = 'https://cdn.july.com/personalisation/personalisation/modern-serif.svg'
const BlockSerif = 'https://cdn.july.com/personalisation/personalisation/block-serif.svg'
const ModernScript = 'https://cdn.july.com/personalisation/personalisation/modern-script.svg'

const blockSVG = 'https://cdn.july.com/personalisation/personalisation/new/BLOCK.svg'
const boldSVG = 'https://cdn.july.com/personalisation/personalisation/new/BOLD.svg'
const bubbleSVG = 'https://cdn.july.com/personalisation/personalisation/new/BUBBLE.svg'
const graphicSVG = 'https://cdn.july.com/personalisation/personalisation/new/GRAPHIC.svg'
const heritageSVG = 'https://cdn.july.com/personalisation/personalisation/new/HERITAGE.svg'
const modernScriptSVG = 'https://cdn.july.com/personalisation/personalisation/new/MODERN-SCRIPT.svg'
const decorativeSVG = 'https://cdn.july.com/personalisation/personalisation/new/DECORATIVE.svg'
const retroSerifSVG = 'https://cdn.july.com/personalisation/personalisation/new/RETRO-SERIF.svg'
const retroSVG = 'https://cdn.july.com/personalisation/personalisation/new/RETRO.svg'
const sansSerifSVG = 'https://cdn.july.com/personalisation/personalisation/new/SANS-SERIF.svg'
const serifSVG = 'https://cdn.july.com/personalisation/personalisation/new/SERIF.svg'
const signatureSVG = 'https://cdn.july.com/personalisation/personalisation/new/SIGNATURE.svg'

const textColorsLuggage = {
  Red: '#d71f27',
  'Dark-Pink': '#bb2c55',
  Pink: '#f37876',
  Orange: '#e95624',
  Peach: '#f27f5b',
  Yellow: '#f1bc1e',
  'Dark-Green': '#084221',
  Green: '#4d853d',
  Mint: '#7aaf98',
  'Powder-Blue': '#78abba',
  Blue: '#3f94a8',
  'Ultramarine-Blue': '#2b3491',
  'French-Navy': '#002551',
  Lavender: '#9a7da6',
  'Dark-Purple': '#3a1247',
  White: '#ffffff',
  Grey: '#97999e',
  Black: '#000000',
  Cream: '#d8a68b',
}

const EmailImput = styled.input`
  margin: 3px 0px;
  padding: 0 4px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.68;
  letter-spacing: 0.18px;
  color: #282828;
  width: 85%;
  min-width: 85%;
  border: none;
  outline: none;
  background: #f5f5f5;
  font-family: Objectivity;
  @media (max-width: 800px) {
    text-align: left;
  }
`
const EmailContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  border: solid 1px #ca7b4f;
  border-radius: 4px;
  padding: 0 12px;
  margin-top: 11px;
  background: #f5f5f5;
  height: 50px;

  & > label {
    position: absolute;
    top: -4px;
    left: 15px;
    background-color: white;
    font-size: 12px;
    padding: 0 8px;
  }
`

const TransparentButton = styled.button`
  font-family: Objectivity;
  border-radius: 27px;
  border: solid 1px rgb(var(--${(props) => props.color}));
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  color: rgb(var(--${(props) => props.color}));
  background: transparent;
  height: 49px;
  padding: 0 16px;
  outline: none;
  cursor: pointer;
  @media (max-width: 800px) {
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    font-size: 10px;
    height: 42px;
  }
  @media (min-width: 801px) {
    ${(props) => (props.noDesktop ? 'display: none;' : '')}
  }
  ${(props) => (props.center ? 'margin: 0 auto;' : '')}

  transition: all 0.2s;

  &:hover {
    background-color: rgba(var(--${(props) => props.color}), 0.2);
    color: white;
    border-color: transparent;
  }
  &:active {
    background-color: rgba(var(--${(props) => props.color}), 1);
    color: ${(props) => (props.text ? `rgb(var(--${props.text}))` : `white`)};
    border-color: transparent;
  }
`

const GreenButton = styled.button`
  font-family: Objectivity;
  border-radius: 27px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  color: white;
  border: solid 1px rgb(var(--dark-blue-grey));
  background-color: rgb(var(--dark-blue-grey));
  height: 49px;
  padding: 0 16px;
  outline: none;
  cursor: pointer;
  @media (max-width: 800px) {
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    font-size: 10px;
    height: 42px;
  }
  @media (min-width: 801px) {
    ${(props) => (props.noDesktop ? 'display: none;' : '')}
  }
  ${(props) => (props.center ? 'margin: 0 auto;' : '')}

  transition: all 0.2s;
  border: solid 1px transparent;
  &:hover {
    background-color: rgba(var(--dark-blue-grey), 0.8);
    color: #ffffff;
    border: solid 1px transparent;
  }
  &:active {
    background-color: transparent;
    border: solid 1px rgb(var(--dark-blue-grey));
    color: rgb(var(--dark-blue-grey));
  }

  ${(props) => props.changed === false && 'opacity: 0.5;'}
`

const MobileCategoryContainer = styled.div`
  width: 100px;
  margin: 0 16px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  @media (min-width: 801px) {
    display: none;
  }
`
const MobileCategorText = styled.div`
  font-size: 8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.07px;
  text-align: center;
  color: #282828;
  margin-top: 14px;
`
const Circle = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: relative;
  ${(props) =>
    props.small
      ? `
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
  `
      : `
    min-width: 38px;
    min-height: 38px;
    width: 38px;
    height: 38px;
  `};
  border-radius: 50%;
  border: solid 1px
    ${(props) =>
    props.selected === undefined
      ? '#979797'
      : props.selected === false
        ? props.color === '#ffffff'
          ? '#00000022'
          : props.color
        : '#888'};
  ${(props) =>
    props.selected === true
      ? `
    &:before {
      content: " ";
      position: absolute;
      top:  0;
      left:  0;
      right:  0;
      bottom:  0;
      border-radius: 50%;
      border: 3px solid #fff;
      z-index: 1;
    }
  `
      : ''}

  margin: 4px;
  background-color: ${(props) => (props.color ? props.color : '#d8d8d8')};
  ${(props) => (props.complete ? 'background-color: #000000;' : '')}
  @media (min-width: 801px) {
    ${(props) => (props.mobileOnly ? 'display: none;' : '')}
  }
  cursor: pointer;

  ${(props) => (props.color === '#FFFFFF' ? 'box-shadow: 0px 0px 3px #0004;' : '')};


  ${({ secondColor }) =>
    secondColor !== undefined
      ? `
        &:after {
          position: absolute;
          content: " ";
          background: ${secondColor} none repeat scroll 0% 0%;
          top: 0px;
          bottom: 0px;
          width: 50%;
          border-top-left-radius: 64px;
          border-bottom-left-radius: 64px;
        }
    `
      : ''}

`

const DesktopCategoryContainer = styled.div`
  position: relative;
  display: ${(props) => (props.show ? 'block' : 'none')};
  border-radius: 36.5px;
  padding: 12px 32px;
  ${(props) =>
    props.complete
      ? `
    margin: 8px 12px;
    border: solid 1px #000000;
    background-color: #000000;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.1px;
    text-align: center;
    color: #f6f3ef;
    width: calc(100% - 48px);
  `
      : `
    margin: 8px 24px;
    border: solid 1px #ca7b4f;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.1px;
    text-align: center;
    color: #282828;
    width: calc(100% - 48px);
  `}

  ${(props) =>
    !props.accepted || (!props.canDo && !props.done)
      ? `
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.1px;
    text-align: center;
    color: #cecece;
    border: solid 1px #cecece;
    cursor: auto;
  `
      : ''}

  user-select: none;
  cursor: pointer;

  @media (max-width: 800px) {
    display: none;
  }
`

const CircleCon = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  min-width: 38px;
  min-height: 38px;
  width: 38px;
  height: 38px;
  ${(props) =>
    props.small
      ? `
    min-width: 31px;
    min-height: 31px;
    width: 31px;
    height: 31px;
  `
      : ''};
`

const MobileCategorys = ({
  completedSteps,
  selectedStep,
  setSelectedStep,
  accepted,
  completionAmount,
  isLuggage,
  isBottle,
  displayStep,
  setDisplayStep,
  showPrevious
}) => {
  const MobileCategory = ({ children, step, show, image, imageGrey, dStep }) => {
    return (
      <>
        <MobileCategoryContainer
          show={show}
          onClick={() => {
            if (accepted) {
              setSelectedStep(step)
              setDisplayStep(dStep)
            }
          }}
        >
          <CircleCon show={show} complete={false}>
            <img
              alt="Green Tick Image"
              style={{ width: '100%', height: '100%' }}
              src={accepted ? (completedSteps[`${dStep}`] ? GreenTick : image) : imageGrey}
            />
          </CircleCon>
          <MobileCategorText> {children} </MobileCategorText>
        </MobileCategoryContainer>
      </>
    )
  }
  const DesktopCategory = ({ children, step, show, completionAmount, image, imageGrey, dStep }) => {
    const canDo = completionAmount === dStep - 1
    const done = completionAmount >= dStep
    return (
      <>
        <DesktopCategoryContainer
          canDo={canDo}
          accepted={accepted}
          complete={done}
          show={show}
          onClick={() => {
            if (accepted) {
              setSelectedStep(step)
              setDisplayStep(dStep)
            }
          }}
        >
          <CircleCon
            show={show}
            complete={false}
            small={true}
            style={{
              display: 'inline-block',
              position: 'absolute',
              left: 5,
              top: 4,
              bottom: 5,
            }}
          >
            <img
              alt="Green Tick Image"
              style={{ width: '100%', height: '100%' }}
              src={accepted ? (completedSteps[`${dStep}`] ? GreenTick : image) : imageGrey}
            />
          </CircleCon>
          {children}
        </DesktopCategoryContainer>
      </>
    )
  }
  const show = selectedStep === undefined
  return (
    <>
      {
        showPrevious && (
          <MobileCategory show={show} step={4} dStep={-1} image={GreenTick} imageGrey={GreenTick}>
            PAST SELECTIONS
          </MobileCategory>
        )
      }
      {isBottle && (
        <MobileCategory show={show} step={2} image={Location} imageGrey={LocationGrey} dStep={0}>
          TYPE POSITION
        </MobileCategory>
      )}
      <MobileCategory
        dStep={isBottle ? 1 : 0}
        show={show}
        step={0}
        image={Font}
        imageGrey={FontGrey}
      >
        TYPE & STYLE
      </MobileCategory>
      <MobileCategory
        dStep={isBottle ? 2 : 1}
        show={show}
        step={1}
        image={Color}
        imageGrey={ColorGrey}
      >
        COLOUR
      </MobileCategory>
      {isLuggage && !isBottle && (
        <MobileCategory show={show} step={2} dStep={2} image={Location} imageGrey={LocationGrey}>
          TYPE POSITION
        </MobileCategory>
      )}

      {
        showPrevious && (
          <DesktopCategory show={show} step={4} dStep={-1} image={GreenTick} imageGrey={GreenTick} completionAmount={completionAmount}>
            PAST SELECTIONS
          </DesktopCategory>
        )
      }
      {isBottle && (
        <DesktopCategory
          completionAmount={completionAmount}
          accepted={accepted}
          show={show}
          step={2}
          dStep={0}
          image={Location}
          imageGrey={LocationGrey}
        >
          TYPE POSITION
        </DesktopCategory>
      )}
      <DesktopCategory
        completionAmount={completionAmount}
        accepted={accepted}
        show={show}
        step={0}
        dStep={isBottle ? 1 : 0}
        image={Font}
        imageGrey={FontGrey}
      >
        TYPE & STYLE
      </DesktopCategory>
      <DesktopCategory
        completionAmount={completionAmount}
        accepted={accepted}
        show={show}
        step={1}
        dStep={isBottle ? 2 : 1}
        image={Color}
        imageGrey={ColorGrey}
      >
        COLOUR
      </DesktopCategory>
      {isLuggage && !isBottle && (
        <DesktopCategory
          completionAmount={completionAmount}
          accepted={accepted}
          show={show}
          step={2}
          dStep={2}
          image={Location}
          imageGrey={LocationGrey}
        >
          TYPE POSITION
        </DesktopCategory>
      )}
    </>
  )
}

const ColorTypeSelector = styled.div`
  ${(props) => (props.show === true ? 'display: flex;' : 'display: none;')}
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;
  padding-bottom: 8px;
  background: white;
  @media (min-width: 801px) {
    display: none;
  }
`
const MobileSelectedColor = styled.div`
  ${(props) => (props.show === true ? 'display: flex;' : 'display: none;')}
  position: absolute;
  top: 18px;
  left: 8px;
  right: 0;
  background: white;
  color: ${({ color }) => color};
  font-size: 12px;
  @media (min-width: 801px) {
    display: none;
  }
`
const ColorTypeSelectorOption = styled.div`
  flex-grow: 1;
  text-align: center;
  font-size: 6px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.23px;
  text-align: center;
  color: #f6f3ef;
  padding: 8px;
  ${(props) =>
    props.selected ? 'background-color: #282828;' : 'color: #cecece;border: solid 1px #282828;'}
`

const MobileTextContainer = styled.div`
  ${(props) => (props.show === true ? 'display: block;' : 'display: none;')}
  position: absolute;
  top: ${(props) => (props.chinese ? '-41px' : '-80px')};
  left: 0;
  right: 0;
  padding: 12px 8px;
  padding-bottom: 0;
  background: white;
  @media (min-width: 801px) {
    display: none;
  }
`

const DesktopTextContainer = styled.div`
  ${(props) => (props.show === true ? 'display: block;' : 'display: none;')}
  background: white;
  width: calc(100% - 12px);
  padding: 4px 8px;
  position: absolute;
  top: 150px;
  left: 8px;
  right: 0;
  background: white;
  z-index: 2;
  @media (max-width: 800px) {
    display: none;
  }
`

const FontContainer = styled.div`
  position: relative;
  ${(props) =>
    props.selected === true
      ? '  border: solid 1px #f6f3ef;background-color: #f6f3ef;'
      : 'border: solid 1px #ffffff00;'}
  cursor: pointer;
  @media (min-width: 801px) {
    width: 100%;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 800px) {
    margin-top: 72px;
    margib-bottom: 12px;
    height: calc(100% - 72px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    min-width: 148px;
    flex-direction: column;
  }
  ${(props) => (props.show === true ? '' : 'display: none !important;')}
`
const FontOptions = styled.img`
  ${(props) => (props.show === true ? 'display: block;' : 'display: none;')}
  ${(props) => (props.selected ? 'text-decoration: underline;' : '')}
    @media (min-width: 801px) {
    margin: 8px auto;
    width: calc(100% - 64px);
    ${(props) => props.big === true && 'width: calc(100% - 64px);'}
  }
  @media (max-width: 800px) {
    width: 90px;
  }
`

const BagFontOptions = styled.div`
  ${(props) => (props.show === true ? 'display: block;' : 'display: none;')}
  @media (min-width: 801px) {
    margin: 0px 96px;
    width: calc(100% - 192px);
  }
  @media (max-width: 800px) {
    width: 90px;
  }
`

const CharLimit = styled.div`
  font-family: Objectivity;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.1px;
  color: #282828;
`
const TopMargin = styled.div`
  ${(props) => (props.show === true ? 'display: block;' : 'display: none;')}
  height: 78px;
  min-height: 78px;
  width: 100%;
  @media (max-width: 800px) {
    display: none;
  }
`

const LanguageOption = styled.span`
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.08px;
  color: #7e7e7e;
  margin: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ToolTip = styled.div`
  opacity: ${({ show }) => (show ? '1' : '0')};
  display: ${({ show }) => (show ? 'block' : 'none')};
  width: ${({ width }) => (width ? `${width}px` : '210px')};
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 12px 0;
  border-radius: 6px;
  top: calc(100% + 6px);
  position: absolute;
  z-index: 1;
  line-height: 1.2;

  transition: 0.4s opacity;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #555 transparent;
  }
`

const LanguageImage = styled.img`
  height: 2.5em;
`

const EmojiPicker = (props) => {
  const ref = useRef()

  useEffect(() => {
    const { Picker } = require('emoji-mart')

    new Picker({
      ...props,
      data: async () => {
        const response = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')
        return response.json()
      },
      ref,
    })
  }, [])
  return <div ref={ref} />
}


const returnText = (emoji, textFull) => {
  return {
    currentTarget: {
      value: textFull + emoji.native,
    },
  }
}

const MobileType = ({
  setFont,
  font,
  text,
  textFull,
  handleTextChange,
  selectedStep,
  value,
  handleChange,
  maxLetters,
  currentFontData,
  location,
  setLocation,
  isPhone,
  showToolTip,
  setShowTooltop,
  setColor
}) => {
  const [emojiPickerState, SetEmojiPicker] = useState(false)
  const [emojiToolTip, setEmojiToolTip] = useState(true)
  const { storeLocale } = useShopStoreIncontext((state) => {
    return {
      storeLocale: state.storeLocale,
    }
  })

  const fontNames = {
    // 'decorative': decorativeSVG,
    'heritage': heritageSVG,
    'bold-new': boldSVG,
    'bubble': bubbleSVG,
    'retro-serif': retroSerifSVG,
    'graphic': graphicSVG,
    'sans-serif': sansSerifSVG,
    Retro: retroSVG,
    Cursive: modernScriptSVG,
    ...(storeLocale === 'en' ? {} : { Block: blockSVG }),
    Signature: signatureSVG,
    Serif: serifSVG,
  }

  const wrapperRef = useRef(null)
  const wrapperRef2 = useRef(null)
  useOutsideAlerter(wrapperRef, () => {
    SetEmojiPicker(false)
  })

  useOutsideAlerter(wrapperRef2, () => {
    setEmojiToolTip(false)
  })

  const triggerPicker = (e) => {
    e.preventDefault()
    SetEmojiPicker(!emojiPickerState)
  }

  let [selectedEmoji, setSelectedEmoji] = useState()
  useEffect(() => {
    if (selectedEmoji !== undefined) {
      handleTextChange(returnText(selectedEmoji, textFull))
      setSelectedEmoji()
    }
  }, [selectedEmoji])

  const handleFontChange = (key) => {
    setFont((prev) => {
      if (prev === 'decorative' && key !== 'decorative') {
        setColor('Green')
      } else if (key === 'decorative' && prev !== 'decorative') {
        setColor('Lilac')
      }
      return key
    })
  }

  return (
    <>
      <DesktopTextContainer show={selectedStep === 0}>
        <label
          htmlFor="normal-switch"
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 8,
          }}
        >
          <LanguageOption>
            <LanguageImage src={English} />
          </LanguageOption>
          <Switch
            onChange={() => handleChange(value === 'chinese' ? 'english' : 'chinese')}
            checked={value === 'chinese'}
            onColor="#bbbbbb"
            offColor="#bbbbbb"
            onHandleColor="#666666"
            offHandleColor="#666666"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 2px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
            height={16}
            width={42}
            className="react-switch"
            id="material-switch"
          />
          <LanguageOption>
            <LanguageImage src={Chinese} />
          </LanguageOption>
        </label>
        <div style={{ display: 'flex' }} onClick={() => setShowTooltop(false)}>
          <EmailContainer style={{ width: 'calc(100% - 16px)' }}>
            {/* <label>Your text</label> */}
            <EmailImput placeholder={text} value={textFull} onChange={handleTextChange} />
            <div
              style={{
                padding: '12px 0',
                width: 36,
                cursor: 'pointer',
                color: '#000',
                display: 'flex',
              }}
            >
              {' '}
              <CharLimit>
                {' '}
                <span
                  style={
                    splitter(textFull).length > maxLetters
                      ? { color: 'red', fontWeight: 600 }
                      : {}
                  }
                >
                  {splitter(textFull).length}
                </span>
                /{maxLetters}{' '}
              </CharLimit>
            </div>
            <ToolTip show={showToolTip}> Please enter your monogram text. </ToolTip>
          </EmailContainer>
          <span
            onClick={triggerPicker}
            style={{
              fontSize: 24,
              lineHeight: '50px',
              padding: '0 8px',
              cursor: 'pointer',
              marginTop: 13,
            }}
          >
            <img src={Smile} style={{ height: '1em', width: '1em' }} alt={'Smile Image'} />
          </span>
        </div>
        <CharLimit> {value === 'chinese' && '暂不支持繁体字'} </CharLimit>
        <div style={{ display: emojiPickerState ? 'block' : 'none' }} ref={wrapperRef}>
          <EmojiPicker
            title="Pick your emoji…"
            emoji="point_up"
            theme="light"
            emojiVersion={14}
            onEmojiSelect={setSelectedEmoji}
          />
        </div>
        {isPhone && (
          <div
            style={{
              padding: 8,
              fontSize: 10,
              textDecoration: 'underline',
              fontWeight: 600,
              color: 'rgb(var(--dark-blue-grey))',
              userSelect: 'none',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (isPhone) {
                setLocation((prev) => (prev === 'Side' ? 'Back' : 'Side'))
              }
            }}
          >
            Switch Orientation
          </div>
        )}
      </DesktopTextContainer>

      {Object.keys(fontNames).map((key, index) => (
        <FontContainer
          key={index}
          show={selectedStep === 0 && value === 'english'}
          selected={key === font}
          onClick={() => handleFontChange(key)}
        >
          <FontOptions
            alt=""
            show={selectedStep === 0}
            selected={key === font}
            className={key}
            src={fontNames[key]}
            big={['College', 'Bold', 'Cursive', 'Modern'].includes(key)}
          />
          <div
            style={{
              textAlign: 'center',
              fontSize: 8,
              paddingTop: '12px',
            }}
          >
            {' '}
            MAX LETTERS: {currentFontData[key].letterLimit}{' '}
          </div>
        </FontContainer>
      ))}
      <>
        <FontContainer
          show={selectedStep === 0 && value === 'chinese'}
          selected={font === 'ZH-BLOCK'}
          style={{
            cursor: 'pointer',
            fontSize: '1.5em',
            fontWeight: 300,
            color: textColorsLuggage['Red'],
            textAlign: 'center',
          }}
          onClick={() => setFont((prev) => 'ZH-BLOCK')}
          className="ZH-BLOCK"
        >
          {' '}
          正楷{' '}
        </FontContainer>
        <FontContainer
          show={selectedStep === 0 && value === 'chinese'}
          selected={font === 'ZH-CASUAL'}
          style={{
            cursor: 'pointer',
            fontSize: '1.5em',
            fontWeight: 300,
            color: textColorsLuggage['Dark-Pink'],
            textAlign: 'center',
          }}
          onClick={() => setFont((prev) => 'ZH-CASUAL')}
          className="ZH-CASUAL"
        >
          新青年
        </FontContainer>
        <FontContainer
          show={selectedStep === 0 && value === 'chinese'}
          selected={font === 'ZH-SCRIPT'}
          style={{
            cursor: 'pointer',
            fontSize: '1.5em',
            fontWeight: 300,
            color: textColorsLuggage['Blue'],
            textAlign: 'center',
          }}
          onClick={() => setFont((prev) => 'ZH-SCRIPT')}
          className="ZH-SCRIPT"
        >
          行书
        </FontContainer>
        <FontContainer
          show={selectedStep === 0 && value === 'chinese'}
          selected={font === 'ZH-SER'}
          style={{
            cursor: 'pointer',
            fontSize: '1.5em',
            fontWeight: 300,
            color: textColorsLuggage['Green'],
            textAlign: 'center',
          }}
          onClick={() => setFont((prev) => 'ZH-SER')}
          className="ZH-SER"
        >
          衬线体
        </FontContainer>
        <FontContainer
          show={selectedStep === 0 && value === 'chinese'}
          selected={font === 'ZH-SAN'}
          style={{
            cursor: 'pointer',
            fontSize: '1.5em',
            fontWeight: 300,
            color: textColorsLuggage['Yellow'],
            textAlign: 'center',
          }}
          onClick={() => setFont((prev) => 'ZH-SAN')}
          className="ZH-SAN"
        >
          无衬线体
        </FontContainer>
      </>
      <MobileTextContainer chinese={false} show={selectedStep === 0}>
        <label
          htmlFor="normal-switch"
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 8,
          }}
        >
          <LanguageOption>
            <LanguageImage src={English} />
          </LanguageOption>
          <Switch
            onChange={() => handleChange(value === 'chinese' ? 'english' : 'chinese')}
            checked={value === 'chinese'}
            onColor="#bbbbbb"
            offColor="#bbbbbb"
            onHandleColor="#666666"
            offHandleColor="#666666"
            // handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 2px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
            height={16}
            width={42}
            className="react-switch"
            id="material-switch"
          />
          <LanguageOption>
            <LanguageImage src={Chinese} />
          </LanguageOption>
          {isPhone && (
            <div
              style={{
                padding: 8,
                fontSize: 10,
                textDecoration: 'underline',
                fontWeight: 600,
                color: 'rgb(var(--dark-blue-grey))',
                userSelect: 'none',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (isPhone) {
                  setLocation((prev) => (prev === 'Side' ? 'Back' : 'Side'))
                }
              }}
            >
              Switch Orientation
            </div>
          )}
        </label>
        <EmailContainer
          style={{ width: 'calc(100% - 16px)' }}
          onTouchStart={() => setShowTooltop(false)}
        >
          <EmailImput placeholder={text} value={textFull} onChange={handleTextChange} />
          <div
            style={{
              padding: '12px 0',
              width: 36,
              cursor: 'pointer',
              color: '#000',
              display: 'flex',
            }}
          >
            {' '}
            <span style={{ marginRight: 2, lineHeight: 1.7 }}></span>{' '}
            <img
              ref={wrapperRef2}
              onClick={() => setEmojiToolTip((prev) => !prev)}
              src={Smile}
              alt={'Smile Images'}
            />
          </div>
          <ToolTip show={showToolTip}> Please enter your monogram text. </ToolTip>
          <ToolTip width={300} show={emojiToolTip}>
            {' '}
            Please use your phones keyboard to add emojis.{' '}
          </ToolTip>
        </EmailContainer>
        <CharLimit>
          {' '}
          {splitter(textFull).length}/{maxLetters}{' '}
        </CharLimit>
      </MobileTextContainer>
    </>
  )
}

const BagType = ({ text, setFont, font, textFull, handleTextChange, selectedStep, maxLetters, showToolTip, setShowTooltop }) => {
  const fontNames = {
    'all-serif': 'SERIF',
    'all-sans': 'SANS SERIF',
  }
  return (
    <>
      <DesktopTextContainer show={selectedStep === 0}>
        <EmailContainer style={{ width: 'calc(100% - 16px)' }} onTouchStart={() => setShowTooltop(false)}>
          {' '}
          <EmailImput placeholder={text} value={textFull} onChange={handleTextChange}
          />{' '}
          <div
            style={{
              padding: '12px 0',
              width: 36,
              cursor: 'pointer',
              color: '#000',
              display: 'flex',
            }}
          >
            {' '}
            <CharLimit>
              {' '}
              <span
                style={
                  splitter(textFull).length > maxLetters
                    ? { color: 'red', fontWeight: 600 }
                    : {}
                }
              >
                {splitter(textFull).length}
              </span>
              /{maxLetters}{' '}
            </CharLimit>
          </div>
          <ToolTip show={showToolTip}> Please enter your monogram text. </ToolTip>
        </EmailContainer>
      </DesktopTextContainer>
      <TopMargin show={selectedStep === 0} />
      {Object.keys(fontNames).map((key, index) => (
        <FontContainer
          key={index}
          show={selectedStep === 0}
          selected={key === font}
          onClick={() => setFont((prev) => key)}
        >
          <BagFontOptions
            alt=""
            style={{
              fontSize: '1.2em',
              color: index % 2 === 0 ? '#6A9A52' : '#BBE0EE',
              paddingBottom: 0,
              textAlign: 'center',
            }}
            show={selectedStep === 0}
            selected={key === font}
            className={key}
          >
            {' '}
            {fontNames[key]}{' '}
          </BagFontOptions>
        </FontContainer>
      ))}
      <MobileTextContainer show={selectedStep === 0}>
        <EmailContainer style={{ width: 'calc(100% - 16px)' }}>
          {' '}
          <EmailImput value={textFull} onChange={handleTextChange} />{' '}
          <ToolTip show={showToolTip}> Please enter your monogram text. </ToolTip>
        </EmailContainer>
      </MobileTextContainer>
    </>
  )
}

const EmbosType = ({ text, setFont, font, textFull, handleTextChange, selectedStep, maxLetters, showToolTip, setShowTooltop }) => {
  const fontNames = {
    'sans-serif-embos': 'Sans Serif',
    'serif-embos': 'Serif'
  }

  const fontColours = {
    'sans-serif-embos': 'gold-foil-text',
    'serif-embos': 'silver-foil-text'
  }
  return (
    <>
      <DesktopTextContainer show={selectedStep === 0}>
        <EmailContainer style={{ width: 'calc(100% - 16px)' }} onTouchStart={() => setShowTooltop(false)}>
          {' '}
          <EmailImput placeholder={text} value={textFull} onChange={handleTextChange}
          />{' '}
          <div
            style={{
              padding: '12px 0',
              width: 36,
              cursor: 'pointer',
              color: '#000',
              display: 'flex',
            }}
          >
            {' '}
            <CharLimit>
              {' '}
              <span
                style={
                  splitter(textFull).length > maxLetters
                    ? { color: 'red', fontWeight: 600 }
                    : {}
                }
              >
                {splitter(textFull).length}
              </span>
              /{maxLetters}{' '}
            </CharLimit>
          </div>
          <ToolTip show={showToolTip}> Please enter your monogram text. </ToolTip>
        </EmailContainer>
      </DesktopTextContainer>
      <TopMargin show={selectedStep === 0} />
      {Object.keys(fontNames).map((key, index) => (
        <FontContainer
          key={index}
          show={selectedStep === 0}
          selected={key === font}
          onClick={() => setFont((prev) => key)}
        >
          <BagFontOptions
            alt=""
            style={{
              fontSize: '1.2em',
              color: index % 2 === 0 ? '#6A9A52' : '#BBE0EE',
              paddingBottom: 0,
              textAlign: 'center',
            }}
            show={selectedStep === 0}
            selected={key === font}
            className={`${key} ${fontColours[key]}`}
          >
            {' '}
            {fontNames[key]}{' '}
          </BagFontOptions>
        </FontContainer>
      ))}
      <MobileTextContainer show={selectedStep === 0}>
        <EmailContainer style={{ width: 'calc(100% - 16px)' }}>
          {' '}
          <EmailImput value={textFull} onChange={handleTextChange} />{' '}
          <ToolTip show={showToolTip}> Please enter your monogram text. </ToolTip>
        </EmailContainer>
      </MobileTextContainer>
    </>
  )
}

const PositionContainer = styled.div`
  position: relative;
  border: solid 1px #ffffff00;
  ${(props) => (props.show === true ? '' : 'display: none;')}
  ${(props) =>
    props.selected === true
      ? '  border: solid 1px #f6f3ef;background-color: #f6f3ef;'
      : 'border: solid 1px #ffffff00;'}
  cursor: pointer;
  @media (max-width: 800px) {
    height: 100%;
    padding: 16px 16px;
  }
  @media (min-width: 801px) {
    width: 100%;
    padding: 16px 64px;
  }
`
const PositionImage = styled.img`
  max-width: 86px;
  max-height: 86px;
  ${(props) => (props.top ? 'margin: 46px 0 -46px 0;' : '')};
  mix-blend-mode: darken;
`

const PositionName = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.1px;
  text-align: right;
  color: #000000;
  @media (max-width: 800px) {
    font-size: 8px;
  }
`

const MobilePosition = ({
  selectedCaseColor,
  caseSize,
  setLocation,
  selectedStep,
  location,
  isLuggage,
  isMe,
  colorNames,
  isBottle,
  isPhone,
  isTrunk,
  locations,
  imageName,
}) => {
  return (
    <>
      {locations.includes('Top') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'Top'}
          onClick={() => setLocation('Top')}
        >
          <PositionName> {isBottle ? 'LID' : 'TOP'} </PositionName>
          <PositionImage
            top={isBottle === false || isBottle == undefined}
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/top/${selectedCaseColor + 1 < 10 ? '0' : ''
              }${selectedCaseColor + 1}.webp`}
            alt={'Case Personalisation Image'}
          />
        </PositionContainer>
      )}
      {locations.includes('Side') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'Side'}
          onClick={() => setLocation('Side')}
        >
          <PositionName> {isPhone ? 'HORIZONTAL' : 'SIDE'} </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/side/${selectedCaseColor + 1 < 10 ? '0' : ''
              }${selectedCaseColor + 1}.webp`}
            alt={'Horizontal Personalisation Image'}
          />
        </PositionContainer>
      )}
      {locations.includes('Back') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'Back'}
          onClick={() => setLocation('Back')}
        >
          <PositionName> {isBottle ? 'SIDE' : isPhone ? 'Vertical' : 'Back'} </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/back/${selectedCaseColor + 1 < 10 ? '0' : ''
              }${selectedCaseColor + 1}.webp`}
            alt={'Side Personalisation Image'}
          />
        </PositionContainer>
      )}
      {locations.includes('Front') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'Front'}
          onClick={() => setLocation('Front')}
        >
          <PositionName> {'Front'} </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/front/${selectedCaseColor + 1 < 10 ? '0' : ''
              }${selectedCaseColor + 1}.webp`}
            alt={'Front Personalisation Image'}
          />
        </PositionContainer>
      )}
      {locations.includes('Horizontal') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'Horizontal'}
          onClick={() => setLocation('Horizontal')}
        >
          <PositionName> {'Horizontal'} </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/horizontal/${selectedCaseColor + 1 < 10 ? '0' : ''
              }${selectedCaseColor + 1}.webp`}
            alt={'Horizontal Personalisation Image'}
          />
        </PositionContainer>
      )}
      {locations.includes('Vertical') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'Vertical'}
          onClick={() => setLocation('Vertical')}
        >
          <PositionName> {'Vertical'} </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/vertical/${selectedCaseColor + 1 < 10 ? '0' : ''
              }${selectedCaseColor + 1}.webp`}
            alt={'Vertical Personalisation Image'}
          />
        </PositionContainer>
      )}

      {locations.includes("horizontal") && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === "horizontal"}
          onClick={() => setLocation("horizontal")}
        >
          <PositionName> Horizontal </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/horizontal/${selectedCaseColor + 1 < 10 ? "0" : ""
              }${selectedCaseColor + 1}.webp`}
            alt={"Right Personalisation Image"}
          />
        </PositionContainer>
      )}
      {locations.includes("vertical") && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === "vertical"}
          onClick={() => setLocation("vertical")}
        >
          <PositionName> Vertical </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/vertical/${selectedCaseColor + 1 < 10 ? "0" : ""
              }${selectedCaseColor + 1}.webp`}
            alt={"Right Personalisation Image"}
          />
        </PositionContainer>
      )}
      {locations.includes("open") && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === "open"}
          onClick={() => setLocation("open")}
        >
          <PositionName> Open </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/open/${selectedCaseColor + 1 < 10 ? "0" : ""
              }${selectedCaseColor + 1}.webp`}
            alt={"Right Personalisation Image"}
          />
        </PositionContainer>
      )}
      {locations.includes("closed") && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === "closed"}
          onClick={() => setLocation("closed")}
        >
          <PositionName> Closed </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/closed/${selectedCaseColor + 1 < 10 ? "0" : ""
              }${selectedCaseColor + 1}.webp`}
            alt={"Right Personalisation Image"}
          />
        </PositionContainer>
      )}


      {locations.includes('Left') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'Left'}
          onClick={() => setLocation('Left')}
        >
          <PositionName> Left </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/back/${selectedCaseColor + 1 < 10 ? '0' : ''
              }${selectedCaseColor + 1}.webp`}
            alt={'Left Personalisation Image'}
          />
        </PositionContainer>
      )}
      {locations.includes('Right') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'Right'}
          onClick={() => setLocation('Right')}
        >
          <PositionName> Right </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/${imageName.toLowerCase()}/back/${selectedCaseColor + 1 < 10 ? '0' : ''
              }${selectedCaseColor + 1}.webp`}
            alt={'Right Personalisation Image'}
          />
        </PositionContainer>
      )}

      {locations.includes('center-landscape') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'center-landscape'}
          onClick={() => setLocation('center-landscape')}
        >
          <PositionName> Center Landscape </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/notebook/centre-landscape.webp`}
            alt={'Center Landscape Image'}
          />
        </PositionContainer>
      )}
      {locations.includes('center-portrait') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'center-portrait'}
          onClick={() => setLocation('center-portrait')}
        >
          <PositionName> Center Portrait </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/notebook/centre-portrait.webp`}
            alt={'Center Portrait Image'}
          />
        </PositionContainer>
      )}
      {locations.includes('bottom-left') && (
        <PositionContainer
          show={selectedStep === 2}
          selected={location === 'bottom-left'}
          onClick={() => setLocation('bottom-left')}
        >
          <PositionName> Bottom Left </PositionName>
          <PositionImage
            src={`https://cdn.july.com/personalisation/cases/notebook/bottom-left.webp`}
            alt={'Bottom Left'}
          />
        </PositionContainer>
      )}
    </>
  )
}

const PreviousContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #ffffff00;
  ${(props) => (props.show === true ? '' : 'display: none;')}
  ${(props) =>
    props.selected === true
      ? '  border: solid 1px #f6f3ef;background-color: #f6f3ef;'
      : 'border: solid 1px #ffffff00;'}
  cursor: pointer;
  @media (max-width: 800px) {
    height: 100%;
    padding: 16px 16px;
  }
  @media (min-width: 801px) {
    width: 100%;
    padding: 16px 16px;
  }
`

const MobilePrevious = ({
  previousPersonalisation,
  selectedStep,

  text,
  handleTextChange,
  font,
  setFont,
  color,
  setColor,
  shadow,
  setShadow,
  location,
  setLocation,
}) => {
  const setToPrevious = (labelData) => {
    handleTextChange({
      currentTarget: {
        value: labelData.text
      },
    })
    setFont(prev => labelData.font)
    setColor(labelData.color)
    setShadow(labelData.shadow)
    setLocation(labelData.position)
  }
  const testIfMatch = (labelData) => {
    const compareLabelData = {
      text: text,
      font: font.toLowerCase(),
      position: location,
      color,
      ...(['block', 'college', 'heritage'].includes(font.toLowerCase()) ? { shadow } : {}),
    }

    return JSON.stringify(compareLabelData) === JSON.stringify(labelData)
  }
  return (
    <>
      {
        previousPersonalisation.map((persData, index) => {
          return (
            <PreviousContainer
              show={selectedStep === 4}
              selected={testIfMatch(persData)}
              onClick={() => setToPrevious(persData)}
              key={index}
            >
              <Word
                refreshToken={persData.font + persData.location}
                left={false}
                size={{
                  height: 50,
                  width: 150,
                  padding: {
                    top: 13.3,
                    bottom: 13.3,
                    left: 18,
                    right: 18,
                  },
                }}
                mainCol={persData.color}
                backCol={persData.shadow}
                letters={persData.text}
                font={persData.font.toLowerCase()}
                fontSize={32}
              />
            </PreviousContainer>
          )
        })
      }
    </>
  )
}

const ColorHeading = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.1px;
  text-align: center;
  color: #282828;
  margin: 0 auto;
  @media (max-width: 800px) {
    display: none;
  }
  ${(props) => (props.show === true ? '' : 'display: none;')}
  ${(props) => (props.top === true ? 'margin-top: 40px;' : '')}
  ${(props) => (props.sub === true ? 'margin: 8px auto;margin-bottom: 40px;' : '')}
`

const ColorContainer = styled.div`
  display: flex;
  @media (max-width: 800px) {
    display: none;
  }
  flex-wrap: wrap;
  width: calc(100% - 128);
  min-height: 275px;
  ${({ small }) => (small === true ? 'min-height: 90px;' : '')}
  margin: 0 64px;
  align-items: center;
  justify-content: center;
  ${(props) => (props.show === true ? '' : 'display: none;')}
`

const PreviewPanel = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  background-color: #f5f3f3;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const HeaderTextMobile = styled.div`
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: -0.61px;
  color: #000000;
  margin: 16px 24px;
  padding: 12px 0;
  @media (min-width: 801px) {
    display: none;
  }
`

const HeaderTextDesktop = styled.div`
  font-size: 31px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: -0.91px;
  text-align: center;
  color: #000000;
  margin: 32px 8px;
  padding: 12px 0;
  justify-self: flex-start;
  @media (max-width: 800px) {
    display: none;
  }
`

const SelectorPanel = styled.div`
  width: 100%;
  position: relative;
  background: white;
  @media (min-width: 801px) {
    width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`
const SliderContainer = styled.div`
  height: 125px;
  width: 100%;
  border: solid 1px #f6f3ef;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    overflow-x: scroll !important;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 8px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
  @media (min-width: 801px) {
    overflow-x: scroll !important;
    flex-direction: column;
    justify-content: ${(props) => (props.selected ? 'flex-start' : 'center')};
    flex-grow: 1;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    /* ${(props) =>
    props.menuOpen &&
    `
      ::after {
        content:"";
        display: block;
        width: 100%;
        position: absolute;
        bottom: 98px;
        right: 8px;
        width: calc(100% - 8px);
        left: 0;
        height: 48px;
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 100%);
      }
    `} */
  }
`
const ContentContanier = styled.div`
  height: 100%;
  position: relative;
  background: #f8f8f8;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  @media (min-width: 801px) {
    flex-direction: row-reverse;
  }
`

const MobileColors = ({
  setColor,
  setShadow,
  color,
  shadow,
  colorType,
  font,
  setColorType,
  selectedStep,
  textColors,
}) => {
  const setMobileColor = (key) => {
    if (colorType === 0) {
      return setColor(key)
    }
    return setShadow(key)
  }

  const selectShadow = (font === 'Block' || font === 'College' || font === 'heritage')

  return (
    <>
      <br />
      {Object.keys(textColors).map((key, index) => {
        const selected = (colorType === 0 ? color : shadow) === key
        return (
          <Circle
            key={index}
            mobileOnly={true}
            show={selectedStep === 1}
            onClick={() => setMobileColor(key)}
            selected={selected}
            color={textColors[key]}
          />
        )
      })}
      <ColorHeading show={selectedStep === 1}>SELECT COLOUR</ColorHeading>
      <ColorHeading sub={true} show={selectedStep === 1}>
        {color.replace('-', ' ')}
      </ColorHeading>
      <ColorContainer small={font === 'decorative' || font.includes('embos')} show={selectedStep === 1}>
        {Object.keys(textColors).map((key, index) => {
          const selected = color === key
          if (typeof textColors[key] === 'object') {
            return (
              <Circle
                key={index}
                small={true}
                mobileOnly={false}
                show={selectedStep === 1}
                onClick={() => setColor(key)}
                selected={selected}
                color={textColors[key][0]}
                secondColor={textColors[key][1]}
              />
            )
          }
          return (
            <Circle
              key={index}
              small={true}
              mobileOnly={false}
              show={selectedStep === 1}
              onClick={() => setColor(key)}
              selected={selected}
              color={textColors[key]}
            />
          )
        })}
      </ColorContainer>
      <ColorHeading
        top={true}
        show={selectShadow && selectedStep === 1}
      >
        {' '}
        SELECT SHADOW <br /> COLOUR{' '}
      </ColorHeading>
      <ColorHeading
        sub={true}
        show={selectShadow && selectedStep === 1}
      >
        {shadow?.replace('-', ' ')}
      </ColorHeading>
      <ColorContainer show={selectShadow && selectedStep === 1}>
        {Object.keys(textColors).map((key, index) => {
          const selected = shadow === key
          return (
            <Circle
              key={index}
              small={true}
              mobileOnly={false}
              show={selectedStep === 1}
              onClick={() => setShadow(key)}
              selected={selected}
              color={textColors[key]}
            />
          )
        })}
      </ColorContainer>
      <ColorTypeSelector show={selectShadow && selectedStep === 1}>
        <ColorTypeSelectorOption selected={colorType === 0} onClick={() => setColorType(0)}>
          1. SELECT MAIN COLOUR
        </ColorTypeSelectorOption>
        <ColorTypeSelectorOption selected={colorType === 1} onClick={() => setColorType(1)}>
          2. SELECT SHADOW COLOUR
        </ColorTypeSelectorOption>
      </ColorTypeSelector>
      <MobileSelectedColor show={selectedStep === 1}>
        SELECTED:{' '}
        <span
          style={{
            color: textColors[colorType === 0 ? color : shadow],
            paddingLeft: 4,
          }}
        >
          <b>{(colorType === 0 ? color : shadow).replace('-', ' ').toUpperCase()}</b>
        </span>
      </MobileSelectedColor>
    </>
  )
}

const TextBottomMargin = styled.div`
  @media (min-width: 801px) {
    margin-bottom: 109px;
  }
`

const ConfirmCopy = styled.div`
  height: 100%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.08px;
  text-align: center;
  color: #282828;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const AcceptDiv = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 16px;
  right: 0;
  left: 0;
  @media (min-width: 801px) {
    left: 300px;
    bottom: 15%;
    & button {
      padding: 0 32px;
    }
  }
  @media (max-width: 800px) {
    & button {
      width: calc(100% - 31px);
    }
  }
`

const DesktopConfirmHeading = styled.div`
  font-size: 38px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: -1.11px;
  text-align: center;
  color: #000000;
  margin-bottom: 32px;
  @media (max-width: 800px) {
    display: none;
  }
`

const MobileConfirmHeading = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: -0.7px;
  text-align: center;
  color: #000000;
  margin-bottom: 16px;
  @media (min-width: 801px) {
    display: none;
  }
`

const ConfirmSubHeading = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: -0.22px;
  text-align: center;
  color: #282828;
  padding: 0 8px;
  @media (max-width: 800px) {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.15px;
    text-align: center;
    color: #242424;
  }
`

const InfomationConfirmSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 32px;
  @media (max-width: 800px) {
    margin-top: 16px;
    margin-bottom: 12px;
    flex-direction: column;
  }
`

const InfoPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 16px;
  width: 220px;
  @media (max-width: 800px) {
    flex-direction: row;
    align-items: center;
    width: auto;
  }
`

const InfoPanelImage = styled.img`
  height: 130px;
  @media (max-width: 800px) {
    height: auto;
    width: 100px;
  }
`

const InfoPanelCopy = styled.div`
  text-align: center;
  @media (max-width: 800px) {
    text-align: left;
    flex-grow: 1;
    margin-top: 16px;
    margin-left: 12px;
  }
`

const InfomationPanel = ({ image, copy }) => {
  return (
    <InfoPanelContainer>
      <InfoPanelImage src={image} />
      <InfoPanelCopy>{copy}</InfoPanelCopy>
    </InfoPanelContainer>
  )
}

const Selector = ({
  selectedStep,
  isLuggage,
  completionAmount,
  accepted,
  completedSteps,
  setSelectedStep,
  isMe,
  isPhone,
  setFont,
  font,
  textFull,
  handleTextChange,
  value,
  handleChange,
  textColors,
  setColorType,
  setColor,
  color,
  shadow,
  location,
  setLocation,
  setAccepted,
  setShadow,
  colorType,
  colorNames,
  caseSize,
  handleAddToCart,
  handleDone,
  selectedCaseColor,
  maxLetters,
  isBottle,
  text,
  isEmbos,
  changed,
  currentFontData,
  displayStep,
  setDisplayStep,
  isTrunk,
  locations,
  imageName,
  caseTypeName,
  previousPersonalisation
}) => {
  const Colours = 'COLOUR'
  const Personalise = 'Personalise'

  let title = ''
  switch (selectedStep) {
    case 0:
      title = (
        <>
          <div style={{ fontSize: 8, lineHeight: 4, marginBottom: 12 }}>
            {' '}
            STEP 1/{isLuggage ? '3' : '2'}{' '}
          </div>
          Text & style.
          <TextBottomMargin />
        </>
      )
      break
    case 1:
      title = (
        <>
          <div style={{ fontSize: 8, lineHeight: 4, marginBottom: 12 }}>
            {' '}
            STEP 2/{isLuggage ? '3' : '2'}{' '}
          </div>
          {Colours}.
        </>
      )
      break
    case 2:
      title = (
        <>
          <div style={{ fontSize: 8, lineHeight: 4, marginBottom: 12 }}>
            {' '}
            STEP 3/{isLuggage ? '3' : '2'}{' '}
          </div>
          Text Position.
        </>
      )
      break
    default:
      title = (
        <>
          {' '}
          {Personalise} <br /> your {caseTypeName}.{' '}
        </>
      )
  }
  if (accepted === false) {
    title = ''
  }

  const preHandleDone = () => {
    const regexPattern = /^[a-zA-Z\s.]+$/;
    if (isLuggage || isBottle || isMe || isEmbos) {
      if (selectedStep === 0 && textFull.length === 0) {
        setShowTooltop(true)
      } else {
        handleDone()
      }
    } else {
      if ((selectedStep === 0 && textFull.length === 0) || (!regexPattern.test(textFull))) {
        setShowTooltop(true)
      } else {
        handleDone()
      }
    }
  }

  const [showToolTip, setShowTooltop] = useState(false)

  return (
    <SelectorPanel>
      <HeaderTextDesktop> {title} </HeaderTextDesktop>
      <SliderContainer menuOpen={selectedStep != undefined} selected={selectedStep != undefined}>
        <MobileCategorys
          showPrevious={previousPersonalisation.length > 0}
          isLuggage={isLuggage}
          completionAmount={completionAmount}
          accepted={accepted}
          completedSteps={completedSteps}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
          displayStep={displayStep}
          setDisplayStep={setDisplayStep}
          isBottle={isBottle || caseSize === 'notebook'}
        />
        {isLuggage || isMe ? (
          <MobileType
            showToolTip={showToolTip}
            setShowTooltop={setShowTooltop}
            isPhone={isPhone}
            setLocation={setLocation}
            location={location}
            setFont={setFont}
            font={font}
            text={text}
            textFull={textFull}
            handleTextChange={handleTextChange}
            selectedStep={selectedStep}
            value={value}
            handleChange={handleChange}
            maxLetters={maxLetters}
            currentFontData={currentFontData}
            setColor={setColor}
          />
        ) : isEmbos ? (
          <EmbosType
            setFont={setFont}
            font={font}
            textFull={textFull}
            handleTextChange={handleTextChange}
            selectedStep={selectedStep}
            maxLetters={maxLetters}
            showToolTip={showToolTip}
            setShowTooltop={setShowTooltop}
            text={text} />
        ) : (
          <BagType
            setFont={setFont}
            font={font}
            textFull={textFull}
            handleTextChange={handleTextChange}
            selectedStep={selectedStep}
            maxLetters={maxLetters}
            showToolTip={showToolTip}
            setShowTooltop={setShowTooltop}
            text={text}
          />
        )}
        <MobileColors
          textColors={textColors}
          selectedStep={selectedStep}
          font={font}
          setColorType={setColorType}
          setColor={setColor}
          setShadow={setShadow}
          color={color}
          shadow={shadow}
          colorType={colorType}
        />
        {isLuggage && (
          <MobilePosition
            colorNames={colorNames}
            isLuggage={isLuggage}
            isPhone={isPhone}
            location={location}
            selectedStep={selectedStep}
            selectedCaseColor={selectedCaseColor}
            caseSize={caseSize}
            setLocation={setLocation}
            isBottle={isBottle}
            isMe={isMe}
            isTrunk={isTrunk}
            locations={locations}
            imageName={imageName}
          />
        )}
        <MobilePrevious
          selectedStep={selectedStep}
          previousPersonalisation={previousPersonalisation}

          text={text}
          handleTextChange={handleTextChange}
          font={font}
          setFont={setFont}
          color={color}
          setColor={setColor}
          shadow={shadow}
          setShadow={setShadow}
          location={location}
          setLocation={setLocation}

        />
      </SliderContainer>
      <div
        style={{
          margin: '16px 0',
          display: 'flex',
          justifyContent: selectedStep === undefined ? 'center' : 'space-evenly',
        }}
      >
        {accepted === false ? (
          <>
            <AcceptDiv>
              <GreenButton onClick={() => setAccepted(true)} style={{ margin: '0 8px' }}>
                {' '}
                ACCEPT & CONTINUE{' '}
              </GreenButton>
            </AcceptDiv>
            <div style={{ height: 49 }} />
          </>
        ) : selectedStep === undefined ? (
          <>
            <GreenButton
              changed={changed}
              onClick={handleAddToCart}
              style={{ margin: '0 8px', flexGrow: 1 }}
            >
              SAVE
            </GreenButton>
            {/* <TransparentButton color="silver" style={{margin: '0 8px', flexGrow: 1}}> Share </TransparentButton> */}
          </>
        ) : (
          <>
            <GreenButton
              tabIndex={0}
              onKeyDown={preHandleDone}
              onClick={preHandleDone}
              style={{ margin: '0 8px', flexGrow: 1 }}
            >
              CONFIRM
            </GreenButton>
            <TransparentButton
              style={{ margin: '0 8px', flexGrow: 1 }}
              tabIndex={0}
              onKeyDown={() => setSelectedStep(undefined)}
              onClick={() => setSelectedStep(undefined)}
              color="dark-blue-grey"
            >
              CANCEL
            </TransparentButton>
          </>
        )}
      </div>
    </SelectorPanel>
  )
}

export default Selector
